import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_DAILY_REPORT_INFO,
        GET_DAILY_REFUND_REPORT_INFO,
        GET_PACKAGE_REPORT,
        GET_YEARLY_SALES_REPORT,
        GET_MONTHLY_SALES_REPORT,
        GET_BALANCE_REPORT,
        GET_DEPOSIT_REPORT,
        GET_MONTHLY_BALANCE_REPORT,
        GET_MONTHLY_TRANS_REPORT,
        GET_REFUND_REPORT,
        GET_CONFIRMATION_REPORT,
        GET_REWARDS_REFERRAL,
        GET_REWARDS_REPORT,
        GET_REWARDS_DETAILS,
        GET_EVENTS_MONTHLY_REPORT,
        GET_TOP_LEVEL_AGENT,
           } = URLS;
    return {
        getDailyReport: (body={}, method='get',date,toSelectedDate) =>
         performRequest( method ? method:'get', GET_DAILY_REPORT_INFO.replace(":fromdate", date).replace(":todate", toSelectedDate), body ),
        getDailyRefundReport: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DAILY_REFUND_REPORT_INFO.replace(":date", date), body ),
        getPackageReport: (body={}, method='get',month,year) =>
         performRequest( method ? method:'get', GET_PACKAGE_REPORT.replace(":month", month).replace(":year", year), body ),
        getYearlySalesReport: (body={}, method='get',year, query) =>
         performRequest( method ? method:'get', `${GET_YEARLY_SALES_REPORT.replace(":year", year)}?type=${query}`, body ),
        getBalanceReport: (body={}, method='get',year,qeury) =>
         performRequest( method ? method:'get', GET_BALANCE_REPORT.replace(":year", year)+qeury, body ),
        getRefundReport: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_REFUND_REPORT.replace(":year", year), body ),
        getConfirmationReport: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_CONFIRMATION_REPORT.replace(":year", year), body ),
         getMonthlyBalanceReport: (body={}, method='get',month,year,qeury) =>
         performRequest( method ? method:'get', GET_MONTHLY_BALANCE_REPORT.replace(":month", month).replace(":year", year)+qeury, body ),
        getMonthlyTransReport: (body={}, method='get',month,year) =>
         performRequest( method ? method:'get', GET_MONTHLY_TRANS_REPORT.replace(":month", month).replace(":year", year), body ),
         getMonthlySalesReport: (body={}, method='get',year,month, query) =>
         performRequest( method ? method:'get', `${GET_MONTHLY_SALES_REPORT.replace(":year", year).replace(":month", month)}?type=${query}`, body ),
        gerDepositReport: (body={}, method='get',year) =>
         performRequest( method ? method:'get', GET_DEPOSIT_REPORT.replace(":year", year), body ),
        getRewardReferal: (body={}, method='get',month,year,qeury) =>
         performRequest( method ? method:'get', GET_REWARDS_REFERRAL.replace(":monthData", month).replace(":yearData", year)+qeury, body ),
        getRewardReport: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_REWARDS_REPORT, body ),
        getRewardDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_REWARDS_DETAILS.replace(':_id', body._id), body ),
        getEventsMonthlyReport: (body={}, method='get',year,month) =>
         performRequest( method ? method:'get', GET_EVENTS_MONTHLY_REPORT.replace(":year", year).replace(":month", month), body ),
         getTopLevelAgent: (body={}, method='get',year,month,limit,code) =>
         performRequest( method ? method:'get', GET_TOP_LEVEL_AGENT.replace(":yearData", year).replace(":monthData", month).replace(":limitData", limit).replace(":codeData", code), body ),
    }
}