import demoLogo from "./logo/logo.png";
import summerbaylogo from "../assets/img/logo/tcb-logo.png"

let  logo = demoLogo

let applicationName = "Island Resort Management System"

let siteName = "https://travoxis.com"

let FooterName = "Travoxios (1165017-V)"

if(process.env.REACT_APP_PRODUCTION_MODE !== "STAGE"){
    applicationName = "Island Resort Centralised Reservation Platform"
    logo = summerbaylogo
    FooterName = " Travoxis Technology SDN BHD"
}

export {
    logo,
    applicationName,
    siteName,
    FooterName
}
