export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"https://app-test.ombakdiveresort.com.my",
        backendUrl: "http://localhost:3100",
        jsReportUrl: "http://localhost:5488",
        travelAgentBackendUrl: "http://localhost:3000",
        adminRepoLocation: "http://localhost:3000",
    };
    if(process.env.REACT_APP_PRODUCTION_MODE ==='TEST'){
        exportResult.appRepoLocation = "https://sg-app.ombak.my";
        exportResult.backendUrl = "https://sg-admin-api.ombak.my";
        exportResult.jsReportUrl= "https://sg-report.ombak.my";
        exportResult.travelAgentBackendUrl =  "https://sg-business-api.ombak.my";
        exportResult.adminRepoLocation = "https://sg-admin.ombak.my";
    }
    if(process.env.REACT_APP_PRODUCTION_MODE ==='PROD'){
        exportResult.appRepoLocation = "https://app.ombakdiveresort.com.my";
        exportResult.backendUrl = "https://admin-api.ombakdiveresort.com.my";
        exportResult.jsReportUrl= "https://report.ombakdiveresort.com.my";
        exportResult.travelAgentBackendUrl =  "https://business-api.ombakdiveresort.com.my";
        exportResult.adminRepoLocation = "https://admin.ombakdiveresort.com.my";
    }
 
    return exportResult;
}